import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import ProgressBar from './order/ProgressBar';
import LoginForm from '../components/order/LoginForm';
import { Wrapper, Block, Title2, Header2, Logo } from '../components/styledComponents';
import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';

const Step5 = ({ login, errorLogin, isLoginLoading, nextStep, loginWithFacebook }) => (
  <Wrapper>
    <Block id="infos">
      <Header2>
        <Link
          to={'/'} style={{ flex: 1, textAlign: 'center' }}
          onClick={() => trackEvent('click', 'home_step5')}
        >
          <Logo src={LogoImg} />
        </Link>
        <ProgressBar step={4} />
        <Title2>Mes informations</Title2>
      </Header2>
      <LoginForm
        errorLogin={errorLogin}
        isLoginLoading={isLoginLoading}
        login={login}
        loginWithFacebook={loginWithFacebook}
        nextStep={nextStep}
      />
    </Block>
  </Wrapper>
);

Step5.propTypes = {
  errorLogin: PropTypes.bool,
  isLoginLoading: PropTypes.bool,
  login: PropTypes.func.isRequired,
  loginWithFacebook: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
};

Step5.defaultProps = {
  errorLogin: false,
  isLoggued: false,
  isLoginLoading: false,
};

export default Step5;
