import React from 'react';
import LoginContainer from '../containers/LoginContainer';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = routes.FastVariantInfos.url;
const Step4Login = () => (
  <Layout slug="FastVariantLogin">
    <LoginContainer nextStep={nextStep} />;
  </Layout>
);
export default Step4Login;
