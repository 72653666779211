import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import OrderContainer from '../../common/OrderContainer';
import Step5 from '../../../components/Step5v1';
import callApi from '../../../services/api';
import { trackEvent, trackUser } from '../../../services/analytics';

class LoginContainer extends Component {
  constructor() {
    super();
    this.state = {};
    this.login = this.login.bind(this);
    this.loginWithFacebook = this.loginWithFacebook.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (window.location.hostname !== 'localhost') {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }

  login(email, password) {
    this.setState({ isLoginLoading: true });
    callApi('login', 'post', { email, password })
      .then((res) => {
        this.setState({ isLoginLoading: false });
        const { customer } = res;
        this.props.dispatch({ type: 'CUSTOMER.SET_CUSTOMER', customer });
        trackUser(customer);
        navigate(this.props.nextStep);
      })
      .catch(() => {
        trackEvent('erreur', 'echec-login');
        this.setState({ errorLogin: true, isLoginLoading: false });
      });
  }

  loginWithFacebook({ accessToken }) {
    this.setState({ isLoginLoading: true });
    callApi('login', 'POST', { fbAccessToken: accessToken }).then(({ customer }) => {
      this.setState({ isLoginLoading: false });
      this.props.dispatch({ type: 'CUSTOMER.SET_CUSTOMER', customer });
      trackUser(customer);
      navigate(this.props.nextStep);
    });
  }

  render() {
    return (
      <OrderContainer>
        <Step5
          errorLogin={this.state.errorLogin}
          isLoggued={this.state.isLoggued}
          isLoginLoading={this.state.isLoginLoading}
          login={this.login}
          loginWithFacebook={this.loginWithFacebook}
          nextStep={this.props.nextStep}
        />
      </OrderContainer>
    );
  }
}

LoginContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
