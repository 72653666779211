import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import FacebookLogin from 'react-facebook-login';
import { Form2, Title4Top, Separator, Input, Button, ErrorText } from '../styledComponents';

class LoginForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Form2>
        <Separator />
        <Title4Top>déjà client ?</Title4Top>
        <Input
          type="email" placeholder="votre adresse email"
          onChange={e => this.setState({ email: e.target.value })}
        />
        <Input
          type="password" placeholder="votre mot de passe"
          onChange={e => this.setState({ password: e.target.value })}
        />
        {this.props.isLoginLoading ?
          <p>chargement</p> :
          <Button secondary type="button" onClick={() => this.props.login(this.state.email, this.state.password)}>
            {'> se connecter <'}
          </Button>
        }
        {this.props.errorLogin && <ErrorText>email/mot de passe incorrect(s)</ErrorText>}
        <Link to={'/mot-de-passe-oublie'}>mot de passe oublié ?</Link>
        <Separator />
        <Title4Top>pas encore de compte ?</Title4Top>
        <Link to={this.props.nextStep}>
          <Button type="button">
            {'> créer un compte <'}
          </Button>
        </Link>
        <Separator />
        <FacebookLogin
          appId="258420877937846"
          autoLoad={false}
          fields="first_name,last_name,email"
          // onClick={console.log}
          callback={this.props.loginWithFacebook}
          textButton="continuer avec Facebook"
          cssClass="kep-login-facebook facebook_button"
          isMobile
          disableMobileRedirect
        />
      </Form2>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  loginWithFacebook: PropTypes.func.isRequired,
  errorLogin: PropTypes.bool.isRequired,
  isLoginLoading: PropTypes.bool.isRequired,
  nextStep: PropTypes.string.isRequired,
};

export default LoginForm;
